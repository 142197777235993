<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="标题"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />

        <e-input
          title="标识"
          :validate="validateInfos.sign"
          v-model:value="modelRef.sign"
        />

        <e-select
          title="分类"
          v-model:value="modelRef.roleId"
          :options="roles"
          @ok="setRemark"
        />

        <a-row style="padding-left: 2px">
          <a-col :span="7" :offset="1">
            <e-input-number title="顺序" v-model:value="modelRef.sort" />
          </a-col>
        </a-row>

        <e-textarea title="描述" v-model:value="modelRef.remark" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { MenuClass } from '@/apis/menu'

const useForm = Form.useForm
const api = new MenuClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')
    const roles = ref([])

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      roles,
      isEdit,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.name = formData.name
        this.modelRef.sign = formData.sign
        this.modelRef.roleId = formData.roleId
        this.modelRef.remark = formData.remark
        this.modelRef.sort = formData.sort
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.name
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)
        this.title = '添加权限'
        this.isEdit = false
      }

      this.getRoles()
      this.visible = true
    },
    onSubmitFun (e) {
      const data = toRaw(e)
      if (this.isEdit) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', data)
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$emit('ok', data)
          this.onClose()
        })
      }
    },

    setRemark (e) {
      const name = this.roles.filter(x => x.value === e)
      console.log(name)
      this.modelRef.remark = name[0].label
    },

    async getRoles () {
      const list =
        this.$store.state.data.ALL_ROLES.length > 0
          ? this.$store.state.data.ALL_ROLES
          : await this.$store.dispatch('GetAllRoles').then()

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.roles = vlist
    }
  }
})
</script>
