<template>
  <e-modal
    :visible="visible"
    title="修改顺序"
    :width="300"
    @close="handleClose"
    @ok="handleOk"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form v-bind="layout">
        <e-input-number :labelCol="4" :valueCol="16" title="顺序" v-model:value="sort" />
      </a-form>
    </a-spin>
  </e-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)
    const sort = ref(0)

    const handleClose = () => {
      sort.value = 0
      loading.value = false
      visible.value = false
    }

    const handleOk = () => {
      loading.value = true
      emit('ok', sort.value)
    }

    return {
      loading,
      visible,
      sort,
      handleClose,
      handleOk
    }
  },

  methods: {
    open (sort) {
      this.sort = sort
      this.visible = true
    }
  }
})
</script>

<style lang="less" scoped></style>
