<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    @close="onClose"
    :footer="null"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form v-bind="layout">
        <a-row>
          <a-col :span="11">
            <e-input
              title="标题"
              :validate="validateInfos.name"
              v-model:value="modelRef.name"
            />
          </a-col>
          <a-col :span="11">
            <e-input
              title="标识"
              :validate="validateInfos.sign"
              v-model:value="modelRef.sign"
            />
          </a-col>
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="onSubmit((e) => onSubmitFun(e))">
                <template #icon>
                  <PlusOutlined />
                </template>
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <div class="list">
        <div class="head">
          <a-row>
            <a-col :span="6">标题</a-col>
            <a-col :span="16">标识</a-col>
            <a-col :span="2">操作</a-col>
          </a-row>
        </div>
        <div class="body">
          <a-row v-for="(v, k) in actionList" :key="k">
            <a-col :span="6">{{ v.name }}</a-col>
            <a-col :span="16">{{ v.sign }}</a-col>
            <a-col :span="2">
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delFormFun(v.id, k)"><DeleteOutlined /></a>
              </a-tooltip>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'
import form from '@/mixins/form'
import { MenuClass } from '@/apis/menu'

const useForm = Form.useForm
const api = new MenuClass()
export default defineComponent({
  components: {
    DeleteOutlined,
    PlusOutlined
  },
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const actionList = ref([])
    const rule = ref({})
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: '请输入标题'
        }
      ],
      sign: [
        {
          required: true,
          message: '请输入标识'
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()

      emit('close')
      visible.value = false
    }

    const reset = () => {
      resetFields()
    }

    return {
      title,
      actionList,
      rule,
      visible,
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose,
      reset
    }
  },

  methods: {
    open (data) {
      this.rule = data
      this.title = '操作权限：' + data.name
      this.actionList = data.actions
      this.visible = true
      this.loading = false
    },
    onSubmitFun (e) {
      const data = toRaw(e)
      data.parentId = this.rule.id
      data.type = 1
      data.roleId = this.rule.roleId

      api
        .create(data)
        .then((id) => {
          data.id = id
          this.actionList.push({ ...data })

          console.log(this.actionList, data)
          this.reset()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    delFormFun (id, index) {
      this.loading = true
      api.del(id).then(() => {
        this.$message.success('删除成功！')
        this.actionList.splice(index, 1)
        this.loading = false
      })
    }
  }
})
</script>

<style lang="less" scoped>
.list {
  border-radius: 5px;
  .head {
    background: rgba(118, 88, 228, 0.3);
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
  }
  .body {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 10px;
    background: #f1f1f1;
    border-radius: 0 0 5px 5px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #1890ff;
    }
  }
}
</style>
